import React, { useEffect } from 'react'
import { graphql } from 'gatsby'
import Layout from '@/components/Layout/Layout'
import Container from '@/components/Layout/Container'
import EventLift from '@/components/EventLift'
import MainHeading from '@/components/MainHeading'
import moment from 'moment'
import Seo from '@/components/Seo'
import Loadmore from '@/components/LoadMore'
import { useTranslation, Trans } from 'gatsby-plugin-react-i18next'
import { seotype } from '@/types.d'
interface Event {
  id: string
  gwEventsDate: string
  title: string
  gwEventsFirstSection: string
  gwEventsTime: string
  uri: string
  gwEventsLocation: string
  featuredImage: any
}
interface EventstData {
  data: {
    form: any
    page: {
      title?: string
      featuredImage?: any
      gwContent?: string
      gwMapImage: string
      people: any
      language: {
        slug: string
      }
      uri: string
      seo: seotype
      translations: []
    }
    mainMenu: any
    langData: any
    wp: any
    allWpGwEvent: {
      nodes: Array<Event>
    }
  }
}

const EventsTemplate = ({ data }: EventstData) => {
  const { t, i18n } = useTranslation()

  useEffect(() => {
    i18n.changeLanguage(page.language.slug)
  }, [])

  const { page } = data
  const { form } = data
  const { allWpGwEvent } = data
  const { mainMenu } = data
  const { wp } = data
  const langData = { languages: wp.languages, currentLang: page.language.slug, translations: page.translations }

  const dateToday = moment().format('YYYY-MM-DD')
  const upcomingEvents: any = []
  const pasteEventsReversed: any = []

  allWpGwEvent.nodes.map((event: any) => {
    if (event.gwEventsDate >= dateToday || (event?.gwEventsDateEnd && event?.gwEventsDateEnd >= dateToday)) {
      upcomingEvents.push(event)
    } else {
      pasteEventsReversed.push(event)
    }
  })

  const pastEvents: any = pasteEventsReversed.reverse()

  return (
    <Layout featuredImage={page.featuredImage} lang={langData} nav={mainMenu} form={form}>
      {page.seo && (
        <Seo
          title={page?.seo?.title}
          description={page?.seo?.metaDesc}
          keywords={page?.seo?.metaKeyword}
          pathname={page.uri}
          lang={page?.language?.slug}
          image={page?.featuredImage?.node?.localFile?.url}
          twitterTitle={page?.seo?.twitterTitle}
          twitterDescription={page?.seo?.twitterDescription}
          opengraphTitle={page?.seo?.opengraphTitle}
          opengraphDescription={page?.seo?.opengraphDescription}
          opengraphImage={page?.seo?.opengraphImage?.localFile?.url}
          twitterImage={page?.seo?.twitterImage?.localFile?.url}
        />
      )}
      <Container>
        <MainHeading className="mb-16">{page.title}</MainHeading>
        <EventLift kloString={t('klo.')} readMoreString={t('Lue lisää')} event={upcomingEvents} />
        <div className="flex justify-center items-center my-20">
          <div className="max-w-3xl w-full">
            <h2 className="text-3xl">
              <Trans>Menneet tapahtumat</Trans>
            </h2>
            {pastEvents && <Loadmore buttonText={t('Katso lisää')} postType="events" posts={pastEvents} />}
          </div>
        </div>
      </Container>
    </Layout>
  )
}

export const EventsyPageQuery = graphql`
  query EventsPageById(
    $id: String
    $postlang: [String]
    $menu: [WpMenuLocationEnum]
    $translang: String
    $formNum: Int
  ) {
    # selecting the current page by id
    page: wpPage(id: { eq: $id }) {
      id
      content
      title
      uri
      ...featuredHeroImage
      ...langdata
      ...seo
    }
    allWpGwEvent(sort: { fields: gwEventsDate, order: ASC }, filter: { language: { slug: { in: $postlang } } }) {
      ...allEvents
    }
    mainMenu: wpMenu(locations: { in: $menu }) {
      ...navigation
    }
    wp {
      ...allLang
    }
    locales: allLocale(filter: { language: { eq: $translang } }) {
      ...stringTranslations
    }
    form: wpGravityFormsForm(formId: { eq: $formNum }) {
      ...GravityFormFields
    }
  }
`

export default EventsTemplate
